import React from "react";
import { Text, View, Image } from "react-native";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

export default function Counter(props) {
  const Tablet = useMediaQuery({ minWidth: 500, maxWidth: 1018 });
  const Desktop = useMediaQuery({ minWidth: 1018 });

  return (
    <View
      style={{
        width: Desktop ? "60%" : Tablet ? "85%" : "100%",
        alignItems: "center",
        paddingLeft: 0,
      }}
    >
      <Text
        style={{
          color: "#cc2419",
          fontSize: 15,
          fontFamily: "Lato_900Black",
          marginTop: 25,
          marginBottom: 7,
          paddingLeft: Desktop ? 55 : 0,
        }}
      >
        UAI TOFU VENDIDOS
      </Text>

      <View
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: Desktop ? 55 : 7,
        }}
      >
        <View
          style={{
            backgroundColor: "#f9be2b",
            width: "100%",
            height: 45,
            borderRadius: 50,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#cc2419",
              fontSize: Desktop ? 27 : 23,
              fontFamily: "Lato_900Black",
              letterSpacing: 9,
            }}
          >0186584
            {/* 0{props.tofuVendido} */}
          </Text>
        </View>
      </View>

      <Text
        style={{
          color: "#cc2419",
          fontSize: 15,
          fontFamily: "Lato_900Black",
          marginTop: 25,
          marginBottom: 7,
          paddingLeft: Desktop ? 55 : 0,
        }}
      >
        DOAÇÃO DOS NOSSOS PARCEIROS
      </Text>
      <View
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: Desktop ? 5 : 55,
        }}
      >
        <Text
          style={{
            color: "#cc2419",
            fontFamily: "Lato_900Black",
            fontSize: 18,
            marginRight: 10,
          }}
        >
          R$
        </Text>
        <View
          style={{
            backgroundColor: "#f9be2b",
            width: "100%",
            height: 45,
            borderRadius: 50,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#cc2419",
              fontSize: Desktop ? 27 : 23,
              fontFamily: "Lato_900Black",
              letterSpacing: 9,
            }}
          >
            00320,80
          </Text>
        </View>
      </View>

      <Image
        style={{
          width: Desktop ? 230 : 180,
          height: 110,
          resizeMode: "contain",
          marginTop: 30,
          marginBottom: 10,
          marginLeft: Desktop ? 50 : 0,
        }}
        source={require("../assets/marcas/vistase.png")}
      />

      <View
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: Desktop ? 5 : 55,
        }}
      >
        <Text
          style={{
            color: "#cc2419",
            fontFamily: "Lato_900Black",
            fontSize: 18,
            marginRight: 10,
          }}
        >
          R$
        </Text>
        <View
          style={{
            backgroundColor: "#f9be2b",
            width: "100%",
            height: 45,
            borderRadius: 50,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#cc2419",
              fontSize: Desktop ? 27 : 23,
              fontFamily: "Lato_900Black",
              letterSpacing: 9,
            }}
          >
            02000,00
          </Text>
        </View>
      </View>

      <Text
        style={{
          color: "#cc2419",
          fontSize: 15,
          fontFamily: "Lato_900Black",
          marginTop: 25,
          marginBottom: 7,
          paddingLeft: Desktop ? 55 : 0,
        }}
      >
        DINHEIRO REVERTIDO TOTAL
      </Text>
      <View
        style={{
          width: "60%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: Desktop ? 5 : 55,
        }}
      >
        <Text
          style={{
            color: "#cc2419",
            fontFamily: "Lato_900Black",
            fontSize: 18,
            marginRight: 10,
          }}
        >
          R$
        </Text>
        <View
          style={{
            backgroundColor: "#f9be2b",
            width: "100%",
            height: 45,
            borderRadius: 50,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#cc2419",
              fontSize: Desktop ? 27 : 23,
              fontFamily: "Lato_900Black",
              letterSpacing: 9,
            }}
          >011650,00
            {/* 0
            {parseFloat(props.dinheiroRevertido)
              .toFixed(2)
              .toString()
              .replace(".", ",")} */}
          </Text>
        </View>
      </View>

      <Image
        style={{
          width: Desktop ? 230 : 180,
          height: 100,
          resizeMode: "contain",
          marginTop: 15,
          marginBottom: 10,
          marginLeft: Desktop ? 78 : 0,
        }}
        source={require("../assets/designer/Elementos_destrinchados/ilustração_site_uaitofu.png")}
      />
    </View>
  );
}
