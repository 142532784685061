import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  Platform,
} from "react-native";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

import { Video } from "expo-av";

import TopbarMobile from "./TopbarMobile";

export default function LandPageMobile() {

  const layout1 = useMediaQuery({ minWidth: 720 });
  const layout2 = useMediaQuery({ minWidth: 500, maxWidth: 719 });
  console.log(Dimensions.get('window').height
)
  return (
    <>
      <TopbarMobile/>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: layout1 ? 120 : layout2 ? 120 : Dimensions.get('window').height < 820 ? 110 : 50,
        }}
      >
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 15,
            marginBottom: 10,
          }}
        >
          <Text
            style={{
              color: "#f9be2b",
              fontFamily: "Lato_900Black",
              fontSize: layout1 ? 33 : 25,
            }}
          >
            Conheça o projeto
          </Text>
          <Image
            style={{
              width: layout1 ? 350 : layout2 ? 300 : 270,
              height: layout1 ? 45 : layout2 ? 40 : 35,
              resizeMode: "stretch",
              alignSelf: "center",
              padding: 0,
              margin: 0,
            }}
            source={require("../assets/designer/abraçandofadas_site_uaitofu.png")}
          />
        </View>
      </View>
      <Video
        source={require("../assets/designer/videos/fadas.mp4")}
        rate={1.0}
        volume={1.0}
        isMuted={false}
        useNativeControls
        resizeMode="stretch"
        shouldPlay={false}
        isLooping
        style={{
          width: layout1 ? 260 : layout2 ? 240 : 215,
          height: layout1 ? 370 : layout2 ? 350 : 320,
          alignSelf: "center",
          borderWidth: 15,
          borderColor: "#fff",
        }}
      />

      <View style={{ width: layout1 ? "80%" : layout2 ? "75%" : "75%" }}>
        <Text
          style={{
            textAlign: "justify",
            textAlignVertical: "center",
            fontFamily: "Lato_400Regular",
            fontSize: layout1 ? 15 : layout2 ? 14 : 13,
            color: "#fff",
            marginBottom: 15,
            marginTop: 15,
          }}
        >
          Estamos colocando mais uma ação em movimento e gostaríamos de
          compartilhar com vocês! O time da UAI Tofu vai{" "}
          <Text style={{ fontFamily: "Lato_900Black" }}>doar R$ 0,05</Text>,
          para cada peça de tofu vendida, para o Santuário das Fadas.
        </Text>
        <Text
          style={{
            textAlign: "justify",
            color: "#fff",
            fontFamily: "Lato_400Regular",
            fontSize: layout1 ? 15 : layout2 ? 14 : 13,
            marginBottom: 15,
          }}
        >
          Acreditamos que só seremos realmente felizes quando até o menor dos
          seres for respeitado.
        </Text>
        <Text
          style={{
            textAlign: "center",
            color: "#f9be2b",
            fontFamily: "Lato_900Black",
          }}
        >
          BHAVATU SABBA MANGALAM
        </Text>
        <Text
          style={[
            {
              textAlign: "center",
              color: "#f9be2b",
              marginBottom: 20,
              fontFamily: "Lato_700Bold_Italic",
              fontSize: 14,
            }
          ]}
        >
          Que todos os seres sejam felizes.
        </Text>
      </View>
    </>
  );
}
