import React from "react";
import {
  StyleSheet,
  Image,
  View,
  TouchableOpacity,
  FlatList, Text
} from "react-native";
import LogoTopbarUaitofu from "../assets/logo-topbar-uaitofu.png";
import * as Linking from "expo-linking";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

import {
    useFonts,
    Lato_400Regular,
    Lato_900Black,
    Lato_700Bold_Italic,
  } from "@expo-google-fonts/lato";

export default function Topbar() {
    let [fontsLoaded] = useFonts({
        Lato_400Regular,
        Lato_900Black,
        Lato_700Bold_Italic,
      });
    const layout2 = useMediaQuery({ maxWidth: 1400, minWidth: 1131 });
    const layout3 = useMediaQuery({ maxWidth: 1130 });
    const layout4 = useMediaQuery({ maxWidth: 930 });

    return (
    <View style={[styles.container, {width: layout2 ? "1100px" : layout3 ? "900px" : "1250px"}]}>
    <View style={[styles.logoContainer, {width: layout2 ? "350px" : layout3 ? "280px" : "400px"}]}>
        <Image source={require("../assets/logo-topbar-uaitofu.png")} style={[styles.logo, {width: layout3 ? "177px" : "235px" , height: layout3 ? "31px" : "41px" }]} />
    </View>
    <View style={[styles.linkContainer, {transform: layout2 ? [{translateX: "50px"}] : [{translateX: "100px"}]}]}>

    <TouchableOpacity style={[styles.link, {marginRight: layout2 ? "75px" : layout3 ? "50px" : "100px" }]}onPress={() => Linking.openURL("#")}>
    <Text style={[styles.linkText, {fontSize: layout3 ? "1.5rem" : "1.7rem"}]}>ABRAÇANDO FADAS</Text>
    </TouchableOpacity>

    <TouchableOpacity style={[styles.link, , {marginRight: layout2 ? "75px" : layout3 ? "50px" : "100px" }]}onPress={() => Linking.openURL("https://pesquisa.uaitofu.com.br/")}>
    <Text style={[styles.linkText2, {fontSize: layout3 ? "1.5rem" : "1.7rem"}]}>NOS DÊ SUA OPINIÃO</Text>
    </TouchableOpacity>

    </View>    

    </View>
        );


}



const styles = StyleSheet.create({
    
    container: {
        display: "flex",  
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "15vh",
        borderRadius: "100px",
        borderWidth: "4px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        borderStyle: "solid",
        borderColor: "#F9BE2B",

        position: "absolute",
        margin: 0,
        padding: 0,
        top: 0,
        left: 0,
        marginTop: "10vh",
    },
    logoContainer: {
        height: "15vh",
        borderRadius: "100px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        borderStyle: "solid",
        borderWidth: "0px",
        transform: [{translateX: "-1px"}],
        borderColor: "#F9BE2B",
        backgroundColor: "#F9BE2B",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
    },
    linkContainer:{
        display: "flex",  
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        
    },
    link:{
    },
    linkText:{
      color: "white",
      fontFamily: "Lato_900Black",
       
    },
    link2:{
    },
    linkText2:{
      color: "#F9BE2B",
      fontFamily: "Lato_900Black",  
    }


  });
