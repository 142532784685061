import React from "react";
import { Text, View, Image, Platform } from "react-native";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

import { Video } from "expo-av";
import Topbar from "./Topbar";

export default function LandPageDesktop() {

  const layout2 = useMediaQuery({ maxWidth: 1350, minWidth: 1131 });
  const layout3 = useMediaQuery({ maxWidth: 1130 });
  const layout4 = useMediaQuery({ maxWidth: 930 });

  return (
    <>
      <Topbar
      />
      

      <View
        style={{
          width: "100%",
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          marginTop: 250,
        }}
      >
        <View
          style={{
            width: layout2 ? "30%" : layout3 ? "35%" : "25%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: layout4 ? 60 : 0,
            marginBottom: 15,
            marginRight: layout4 ? 20 : "10%",
            marginLeft: 100
          }}
        >
          <Text
            style={{
              color: "#f9be2b",
              fontFamily: "Lato_900Black",
              fontSize: 36,
              marginBottom: 3
            }}
          >
            Conheça o projeto
          </Text>
          <Image
            style={{
              width: 305,
              height: 90,
              resizeMode: "contain",
              margin: 0,
              padding: 0,
            }}
            source={require("../assets/designer/abracando-fadas-logo.png")}
          />
          <Text
            style={{
              width: "100%",
              textAlign: "left",
              fontFamily: "Lato_400Regular",
              fontSize: 21,
              lineHeight: 26,
              color: "#fff",
              marginBottom: 15,
              marginTop: 20,
              
            }}
          >
            Estamos colocando mais uma ação em movimento e gostaríamos de
            compartilhar com vocês! O time da UAI Tofu vai{" "}
            <Text style={{ fontFamily: "Lato_900Black" }}>doar R$ 0,05</Text>,
            para cada peça de tofu vendida, para o Santuário das Fadas.
          </Text>
          <Text
            style={{
              textAlign: "left",
              textAlignVertical: "center",
              color: "#fff",
              fontSize: 21,
              lineHeight: 26,
              fontFamily: "Lato_400Regular",
              marginTop: 15,
              marginBottom: 10,
            }}
          >
            Acreditamos que só seremos realmente felizes quando até o menor dos
            seres for respeitado.
          </Text>
          <Text
            style={{
              textAlign: "center",
              color: "#f9be2b",
              fontFamily: "Lato_900Black",
              fontSize: 23,
              marginTop: 8,
            }}
          >
            BHAVATU SABBA MANGALAM
          </Text>
          <Text
            style={[
              {
                textAlign: "center",
                color: "#f9be2b",
                fontFamily: "Lato_700Bold_Italic",
                fontSize: 21,
              }
            ]}
          >
            Que todos os seres sejam felizes.
          </Text>
        </View>
        <Video
          source={require("../assets/designer/videos/fadas.mp4")}
          rate={1.0}
          volume={1.0}
          isMuted={false}
          useNativeControls
          resizeMode="stretch"
          shouldPlay={false}
          isLooping
          style={{
            width: 400,
            height: 595,
            alignSelf: "center",
            borderWidth: 28,
            borderColor: "#fff",
          }}
        />
      </View>
    </>
  );
}
