import React from "react";
import { Text, View, Image } from "react-native";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

export default function Days(props) {
  const Tablet = useMediaQuery({ minWidth: 1250 });
  const Desktop = useMediaQuery({ minWidth: 876 });

  return (
    <>
      {!Desktop && (
        <Image
          style={{
            position: "absolute",
            margin: 0,
            padding: 0,
            top: 0,
            left: 0,
            width: 200,
            height: 200,
            resizeMode: "contain",
            transform: [{ translateX: "-50%" }, { translateY: "10%" }],
          }}
          source={require("../assets/designer/circulo_site_uaitofu.png")}
        />
      )}

      <Image
        style={{ width: 350, height: 200, resizeMode: "contain" }}
        source={require("../assets/designer/Lulu_Animada/Webp.net-gifmaker.gif")}
      />
      <View 
        style={{ width:"100%", display: "flex", flexDirection: "column", alignSelf:"center", alignItems: "center", justifyContent: "center" }}
      >
        <Image
          style={{
            width: Desktop ? "50%" : "80%",
            height: Desktop ? 65 : 45,
            resizeMode: "stretch",
          }}
          source={require("../assets/designer/Elementos_destrinchados/seta_site_uaitofu.png")}
        />

        <View
          style={{
            flexDirection: "row",
            width: "80%",
            alignItems: "center",
            marginBottom: 25,
            marginLeft: Desktop ? "30%" : 5
          }}
        >
          <View
            style={{
              backgroundColor: "#986d3c",
              width: Desktop ? "40%" : "50%",
              height: Desktop ? 65 : 52,
              borderWidth: 4,
              borderColor: "#f9be2b",
              borderRadius: 60,
              alignItems: "flex-end",
              justifyContent: "center",
              paddingLeft: 15,
              marginRight: 10,
            }}
          >
            <Text
              style={{
                margin: 0,
                padding: 0,
                color: "#fff",
                fontSize: 27,
                fontFamily: "Lato_900Black",
                marginRight: 20,
              }}
            >
              {(props.diasPassados + 1).toString()} º
            </Text>
          </View>
          <View>
            <Text
              style={{
                color: "#fff",
                fontSize: 17,
                fontFamily: "Lato_900Black",
              }}
            >
              dias de
            </Text>
            <Text
              style={{
                color: "#fff",
                fontSize: 17,
                fontFamily: "Lato_900Black",
              }}
            >
              arrecadação
            </Text>
          </View>
        </View>
      </View>

    </>
  );
}
