import React from "react";
import {
  StyleSheet,
  Image,
  View,
  TouchableOpacity,
  FlatList, Text
} from "react-native";
import LogoTopbarUaitofu from "../assets/logo-topbar-uaitofu.png";
import * as Linking from "expo-linking";

import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

import {
    useFonts,
    Lato_400Regular,
    Lato_900Black,
    Lato_700Bold_Italic,
  } from "@expo-google-fonts/lato";

export default function TopbarMobile() {
    let [fontsLoaded] = useFonts({
        Lato_400Regular,
        Lato_900Black,
        Lato_700Bold_Italic,
      });
    const layout3 = useMediaQuery({ maxWidth: 650, minWidth: 516});
    const layout4 = useMediaQuery({ maxWidth: 515,  minWidth: 431});
    const layout5 = useMediaQuery({ maxWidth: 430});


    return (
    <View style={[styles.container, {height: layout5 ? "60.28125px" : layout4 ? "72.3375px" : layout3 ? "86.805px" : "104.166px"}]}>
    <View style={[styles.logoContainer, {height: layout5 ? "60.28125px" :  layout4 ? "72.3375px" : layout3 ? "86.805px" : "104.166px"}]}>
        <Image source={require("../assets/logo-topbar-uaitofu.png")} style={[styles.logo, {width: layout5 ? "86.805px" : layout4 ? "104.166px" :  layout3 ? "125px" : "150px" , height: layout5 ? "15.041px" : layout4 ? "18.05px" : layout3 ? "21.66px" : "25.992px" }]} />
    </View>
    <View style={[styles.linkContainer, {transform: layout5 ? [{translateX: "20px"}] : layout4 ? [{translateX: "30px"}] : layout3 ? [{translateX: "45px"}] : [{translateX: "35px"}]}]}>

    <TouchableOpacity style={[styles.link, {marginRight: layout5 ? "5px" : layout4 ? "5px" : layout3 ? "10px" : "15px" }]}onPress={() => Linking.openURL("#")}>
    <Text style={[styles.linkText, {fontSize:  layout5 ? "0.625rem" : layout4 ? "0.75rem" : layout3 ? "0.9rem" : "1.08rem"}]}>ABRAÇANDO<br/>FADAS</Text>
    </TouchableOpacity>

    <TouchableOpacity style={[styles.link, , {marginRight: layout5 ? "5px" : layout4 ? "5px" : layout3 ? "10px" : "15px", marginLeft:"10px" }]}onPress={() => Linking.openURL("https://pesquisa.uaitofu.com.br/")}>
    <Text style={[styles.linkText2, {fontSize: layout5 ? "0.625rem" : layout4 ? "0.75rem" : layout3 ? "0.9rem" : "1.08rem"}]}>NOS DÊ SUA<br/>OPINIÃO</Text>
    </TouchableOpacity>

    </View>    

    </View>
        );


}



const styles = StyleSheet.create({
    
    container: {
        display: "flex",  
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: "100px",
        borderWidth: "4px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        borderStyle: "solid",
        borderColor: "#F9BE2B",
        width: "90%",
        position: "absolute",
        margin: 0,
        padding: 0,
        top: 0,
        left: 0,
        marginTop: "34.716px",
    },
    logoContainer: {
        borderRadius: "100px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        borderStyle: "solid",
        borderWidth: "0px",
        width: "40%",
        transform: [{translateX: "-1px"}],
        borderColor: "#F9BE2B",
        backgroundColor: "#F9BE2B",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
    },
    linkContainer:{
        display: "flex",  
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        
    },
    link:{
    },
    linkText:{
      color: "white",
      fontFamily: "Lato_900Black",
      textAlign: "center",
       
    },
    link2:{
    },
    linkText2:{
      color: "#F9BE2B",
      fontFamily: "Lato_900Black",
      textAlign: "center",  
    }


  });
